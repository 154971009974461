import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import en from '../../../assets/language/en.json';
import { alertActions } from '../../shared/actions/alert.actions';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { globalConstants } from '../../shared/constants/global.constants';
import { IProduct } from '../upselling/upselling.interfaces';
import { promotionCampaignConstants } from './promotion.campaign.constants';
import {
    IPromotionCampaignActions,
    IPromotionCampaignCouponResult,
    IPromotionCampaignData,
    IPromotionCampaignResponse,
} from './promotion.campaign.interfaces';
import { promotionCampaignService } from './promotion.campaign.service';

function getPromotionCampaigns(
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
): (dispatch: Dispatch) => Promise<IPromotionCampaignResponse | AxiosError> {
    return (dispatch) => {
        dispatch(request());
        const pagination = paging ? paging : globalConstants.DEFAULT_PAGINATION;
        return promotionCampaignService.getPromotionCampaigns(pagination, searchText, filters).then(
            (response: IPromotionCampaignResponse) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        );
    };

    function request(): IPromotionCampaignActions {
        return { type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGNS_REQUEST };
    }

    function success(response: IPromotionCampaignResponse): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGNS_SUCCESS,
            promotionCampaignsData: response,
        };
    }

    function failure(error: string): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGNS_FAILURE,
            error,
        };
    }
}

function getPromotionCampaign(campaignId: number): (dispatch: Dispatch) => Promise<IPromotionCampaignData> {
    return (dispatch) => {
        dispatch(request());
        return promotionCampaignService.getPromotionCampaign(campaignId).then(
            (response: IPromotionCampaignData) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                return error;
            },
        ) as Promise<IPromotionCampaignData>;
    };

    function request(): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGN_BY_ID_REQUEST,
        };
    }

    function success(response: IPromotionCampaignData): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGN_BY_ID_SUCCESS,
            promotionCampaign: response,
        };
    }

    function failure(error: string): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_PROMOTION_CAMPAIGN_BY_ID_FAILURE,
            error,
        };
    }
}

const addPromotionCampaign = (
    promotionCampaignData: IPromotionCampaignData,
): ((dispatch: Dispatch) => Promise<IPromotionCampaignData | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return promotionCampaignService.addPromotionCampaign(promotionCampaignData).then(
            (response: IPromotionCampaignData) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.promotion_campaign_added_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.zodError(error));
                return error;
            },
        );
    };

    function request(): IPromotionCampaignActions {
        return { type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.ADD_PROMOTION_CAMPAIGN_REQUEST };
    }

    function success(response: IPromotionCampaignData): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.ADD_PROMOTION_CAMPAIGN_SUCCESS,
            promotionCampaign: response,
        };
    }

    function failure(error: string): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.ADD_PROMOTION_CAMPAIGN_FAILURE,
            error,
        };
    }
};

const updatePromotionCampaign = (
    promotionCampaignData: IPromotionCampaignData,
    promotionCampaignId: number,
): ((dispatch: Dispatch) => Promise<IPromotionCampaignData | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return promotionCampaignService.updatePromotionCampaign(promotionCampaignData, promotionCampaignId).then(
            (response: IPromotionCampaignData) => {
                dispatch(success(response));
                dispatch(alertActions.success(en.promotion_campaign_updated_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.zodError(error));
                return error;
            },
        );
    };

    function request(): IPromotionCampaignActions {
        return { type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.UPDATE_PROMOTION_CAMPAIGN_REQUEST };
    }

    function success(response: IPromotionCampaignData): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.UPDATE_PROMOTION_CAMPAIGN_SUCCESS,
            promotionCampaign: response,
        };
    }

    function failure(error: string): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.UPDATE_PROMOTION_CAMPAIGN_FAILURE,
            error,
        };
    }
};

const deletePromotionCampaign = (
    promotionCampaignId: number,
    completed: boolean,
): ((dispatch: Dispatch) => Promise<AxiosResponse | AxiosError>) => {
    return (dispatch) => {
        dispatch(request());

        return promotionCampaignService.deletePromotionCampaign(promotionCampaignId).then(
            (response: AxiosResponse) => {
                dispatch(success());
                completed && dispatch(alertActions.success(en.promotion_campaign_deleted_successfully));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.response.data.message.toString()));
                dispatch(alertActions.error(error.response.data.message.toString()));
                return Promise.reject(error);
            },
        );
    };

    function request(): IPromotionCampaignActions {
        return { type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.DELETE_PROMOTION_CAMPAIGN_REQUEST };
    }

    function success(): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.DELETE_PROMOTION_CAMPAIGN_SUCCESS,
        };
    }

    function failure(error: string): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.DELETE_PROMOTION_CAMPAIGN_FAILURE,
            error,
        };
    }
};

function getAvailableCouponProducts(selectedRegions: string[]): (dispatch: Dispatch) => Promise<IProduct[]> {
    return (dispatch) => {
        dispatch(request());
        return promotionCampaignService.getAvailableCouponProducts(selectedRegions).then(
            (response: IProduct[]) => {
                dispatch(success(response));
                return response;
            },
            (error: AxiosError) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.zodError(error));
                return error;
            },
        ) as Promise<IProduct[]>;
    };

    function request(): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_AVAILABLE_COUPON_PRODUCTS_REQUEST,
        };
    }

    function success(response: IProduct[]): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_AVAILABLE_COUPON_PRODUCTS_SUCCESS,
            availableProducts: response,
        };
    }

    function failure(error: string): IPromotionCampaignActions {
        return {
            type: promotionCampaignConstants.PROMOTION_CAMPAIGN_ACTION_TYPES.GET_AVAILABLE_COUPON_PRODUCTS_FAILURE,
            error,
        };
    }
}

const testPromotionCampaignCoupon =
    (
        promotionCampaignData: IPromotionCampaignData,
    ): ((dispatch: Dispatch) => Promise<IPromotionCampaignCouponResult[]>) =>
    (dispatch) => {
        return promotionCampaignService.testPromotionCampaignCoupon(promotionCampaignData).then(
            (response) => {
                return response?.data;
            },
            (error: AxiosError) => {
                const data = error.response?.data;
                dispatch(alertActions.error(data ? JSON.stringify(data, null, 2) : error.toString()));
                return null;
            },
        );
    };

export const promotionCampaignActions = {
    getPromotionCampaigns,
    getPromotionCampaign,
    addPromotionCampaign,
    updatePromotionCampaign,
    deletePromotionCampaign,
    getAvailableCouponProducts,
    testPromotionCampaignCoupon,
};
