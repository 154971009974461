import { AxiosResponse } from 'axios';
import { History } from 'history';
import { z } from 'zod';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { IProduct } from '../upselling/upselling.interfaces';
import validationSchemas from './pages/addOrEditPromotionCampaign/formModel/validation.schema';

export type IPromotionCampaignData = z.infer<typeof validationSchemas.zodValidationSchema>;

export enum MarketingRegions {
    DE_AT_STORE = 'DE/AT',
    US_STORE = 'US',
}

export interface ICoupon {
    productId: number;
    discount: {
        type: DiscountType;
        value: number;
    };
    expirationDate: Date;
}

export enum DiscountType {
    PERCENTAGE = 'PERCENTAGE',
    FIXED = 'FIXED',
}

export enum TargetUser {
    REGISTERED_WITH_GRILL = 'REGISTERED_WITH_GRILL',
    REGISTERED_WITHOUT_GRILL = 'REGISTERED_WITHOUT_GRILL',
    GUEST_USERS = 'GUEST_USERS',
}

export enum ContentOrientation {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export enum CampaignDisplay {
    TOP = 'TOP',
    MIDDLE = 'MIDDLE',
}

export interface IPromotionCampaignResponse extends AxiosResponse {
    promotionCampaigns: Array<IPromotionCampaignData>;
    total: number;
}

export interface IPromotionCampaignState {
    promotionCampaigns?: Array<IPromotionCampaignData>;
    promotionCampaign?: IPromotionCampaignData;
    total?: number;
    loading: boolean;
    availableProducts: IProduct[];
}

export interface IPromotionCampaignActions {
    type: string;
    promotionCampaignsData?: IPromotionCampaignResponse;
    promotionCampaign?: IPromotionCampaignData;
    availableProducts?: IProduct[];
    error?: string;
}

export interface IPromotionCampaignProps {
    promotionCampaigns: IPromotionCampaignData[];
    total: number;
    loading: boolean;
    getPromotionCampaigns: (
        paging: IPagination,
        searchText?: string,
        filters?: IFilters,
    ) => Promise<IPromotionCampaignResponse>;
    deletePromotionCampaign: (categoryTagId: number, completed: boolean) => Promise<AxiosResponse>;
}

export interface IAddPromotionCampaignProps {
    history: History;
}

export interface IPromotionCampaignCouponResult {
    discountLinkInApp: string;
    shopifyProduct: {
        id: string;
        title: string;
        onlineStorePreviewUrl?: string;
        onlineStoreUrl?: string;
    };
}
